import React from 'react';

const NotFoundPage = () => (
  <div>
    <h1>Puslapis neegzistuoja</h1>
    <p>Ta prasme nu visiškai nėra.</p>
  </div>
);

export default NotFoundPage;
